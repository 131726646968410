import React, { Component } from 'react';
import i18next from 'i18next';

class Coverage extends Component {
    componentDidMount() {
    }

    render() {
        return (
            <div className="col-lg-10 col-md-12">
                <h1 className="text-center">{i18next.t('Coverage.Header')}</h1>
                <form className="form__text coverage">
                    <img src="/img/coverage.jpg" alt="Coverture Map" />
                    <p className="text-center">
                        <a href="/documents/Couverture.pdf" target="_blank">{i18next.t('Coverage.PdfLink')}</a>
                    </p>
                </form>
                <div className="row button__row">
                    <div className="col-12 text-center pbmt-logo">
                        <img src={'/img/1280px-Monaco_Telecom_logo.png'} alt="Powered by Monaco Telecom" />
                    </div>
                </div>
            </div>
        )
    }
}

export default Coverage;
