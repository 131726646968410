import React from 'react';
import MainContainer from "./MainContainer";
import ConfirmForm from "../pages/ConfirmForm";

export default function ConfirmContainer(props) {
    return (
        <MainContainer className="container">
            <ConfirmForm locale={props.locale}  onToggleLocale={props.onToggleLocale} />
        </MainContainer>
    );
}
