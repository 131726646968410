import React from 'react';
import i18next from 'i18next';

export default function FormField(props) {
    return (
        <div className={props.className}>
            <div className={`input-icon ${props.hasError(props.field) ? 'field-error' : '' }`}>
                <i className="material-icons">{props.icon}</i>
                <input type={props.type} name={props.field} placeholder={i18next.t(props.i18nkey, props.i18ndefault)} value={props.value} pattern={props.pattern} onChange={props.onChange} required={props.required} autoFocus={props.focus} minLength={props.minLength} maxLength={props.maxLength} />
                <small>{props.errorMsg(props.field)}</small>
            </div>
        </div>
    );
}
