import React from 'react';
import RefillForm from "../pages/RefillForm";
import MainContainer from "./MainContainer";
import { log } from '../utils/utils';

export default function RefillContainer(props) {
    log('Refill container, package label: ', props.packageLabel);
    return (
        <MainContainer className="container">
            <RefillForm packageLabel={props.packageLabel} locale={props.locale} countryCode={props.countryCode} />
        </MainContainer>
    );
}
