import React, { Component } from 'react';
import i18next from 'i18next';

class Contact extends Component {
    componentDidMount() {
    }

    render() {
        return (
            <div className="col-lg-9 col-md-10">
                <h1 className="text-center">{i18next.t('Contact.Header')}</h1>
                <form className="form__text">
                    <h3>{i18next.t('Contact.AddressHeader')}</h3>
                    <p>
                        {i18next.t('Contact.Street', 'Quai de l\'Hirondelle - Monaco')}<br />
                        {i18next.t('Contact.Phone')}: <a href="tel:+37797773000">+377 97 77 30 00</a>
                    </p>
                    <p>
                        <small className="imprint-text">
                            <strong>{i18next.t('Contact.Editor', 'Editeur du site')} :</strong><br />
                            {i18next.t('Contact.EditorInfo', 'Monaco Telecom SAM. Société Anonyme Monégasque au capital de 1.687.640 euros, immatriculée au RCI de Monaco sous le N° 97 S 03277 dont le siège social est situé 4-6 avenue Albert II - 98000 MONACO.')}<br />
                            <strong>{i18next.t('Contact.CompanyNO', 'NIS')}</strong> : 6110Z07638 / <strong>{i18next.t('Contact.VatID', 'N° Intracommunautaire')}</strong> : FR 73 0000 42183<br />
                            <strong>{i18next.t('Contact.Host', 'Hébergeur du site')}</strong> : Monaco Telecom SAM.<br />
                            <strong>{i18next.t('Contact.ServiceHeader')}</strong><br />
                            {i18next.t('Contact.Phone')}: <a href="tel:+37799666886">+377 99 66 68 86</a><br />
                            {i18next.t('Contact.Email')}: <a href="mailto: stce@monacom-telecom.mc">stce@monacom-telecom.mc</a>
                        </small>
                    </p>
                </form>
                <div className="row button__row">
                    <div className="col-12 text-center pbmt-logo">
                        <img src={'/img/1280px-Monaco_Telecom_logo.png'} alt="Powered by Monaco Telecom"/>
                    </div>
                </div>
            </div>
        )
    }
}

export default Contact;
