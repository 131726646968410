import i18n from "i18next";
// import LanguageDetector from "i18next-browser-languagedetector";
import XHR from "i18next-xhr-backend";

import translationsEN from '../locales/en-US/translations.json';
import translationsFR from '../locales/fr-FR/translations.json';
import translationsIT from '../locales/it-IT/translations.json';
import { log } from './utils';

i18n
    .use(XHR)
    // .use(LanguageDetector)
    .init({
        debug: true,
        lng: 'fr-FR',
        fallbackLng: 'en-US',   // use en if detected lng is not available
        keySeparator: '.',    // we do not use keys in form messages.welcome
        interpolation: {
            escapeValue: false  // react already safes from xss
        },

        resources: {
            'en-US': {
                translations: translationsEN
            },
            'fr-FR': {
                translations: translationsFR
            },
            'it-IT': {
                translations: translationsIT
            }
        },
        // have a common namespace used around the full app
        ns: ['translations'],
        defaultNS: 'translations'
    }).then(() => log('i18n initialized..'));

export default i18n;
