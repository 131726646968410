import config from './config';


export const getApiUrl = () => {
    const { apiUrl, localUrl } = config;
    const isDevEnv = process.env.NODE_ENV === 'development';

    return isDevEnv ? localUrl : apiUrl;
};

export const combinePath = (part1, part2, params) => {
     if (!part1) {
         return part2;
     }

     if (!part2) {
         return part1;
     }

     let url = '';
     if (part1[part1.length - 1] === '/') {
         if (part2.startsWith('/')) {
             url = part1 + part2.substr(1);
         } else {
             url = part1 + part2;
         }
     } else {
         if (part2.startsWith('/')) {
             url = part1 + part2;
         } else {
             url = part1 + '/' + part2;
         }
     }

     if (params && url) {
         if (url.indexOf('?') >= 0) {
             url = url + '&' + params;
         } else {
             url = url + '?' + params;
         }
     }

     return url;
};

export const log = (message, ...optionalParams) => {
    const { debugMode } = config;
    if (debugMode) {
        console.log(message, optionalParams);
    }
};

export const warn = (message, ...optionalParams) => {
    const { debugMode } = config;
    if (debugMode) {
        console.warn(message, optionalParams);
    }
};

export const error = (message, ...optionalParams) => {
    const { debugMode } = config;
    if (debugMode) {
        console.error(message, optionalParams);
    }
};

export const matchField = (e, name) => {
    return e.field && name && (e.field.toLowerCase() === name.toLowerCase());
};

export const getLangFromLocale = (locale) => {
    if (!locale) {
        locale = window.runConfig.locale;
    }
    const parts = locale.split('-');
    return parts.length === 2 ? parts[0] : locale;
};
