import React, { Component } from 'react';

class MainContainer extends Component {
    render() {
        return (
            <div className="main-container">
                <section id="home" className="cover height-100 imagebg parallax" data-overlay="2">
                    <div className="background-image-holder" style={{ background: "url('/img/backgroundsepm.jpg')", opacity: 1 }}>
                        <img alt="background" src={'./img/backgroundsepm.jpg'} />
                    </div>
                    <div className={this.props.className}>
                        <div className="row justify-content-md-center justify-content-lg-center space--top">
                            {this.props.children}
                        </div>
                    </div>
                </section>
            </div>
        )
    }
}

export default MainContainer; // Don’t forget to use export default!
