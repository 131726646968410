import React, { Component } from 'react';
import i18next from 'i18next';

class Help extends Component {
    constructor(props) {
        super(props);

        let params = new URLSearchParams(window.location.search);

        this.state = {
            locale: params.get('locale') || props.locale
        };
    }


    componentDidMount() {
        if (this.state.locale !== this.props.locale) {
            // state locale comes optionally from parameter and must be used
            this.props.onToggleLocale(this.state.locale);
        }
    }

    render() {
        return (
            <div className="col-lg-9 col-md-10">
                <h1 className="text-center">{i18next.t('Help.Header')}</h1>
                <form className="form__text">
                    <h2 className="text-center">{i18next.t('Help.SubHeader')}</h2>
                    <p><strong>{i18next.t('Help.Q1')}</strong> {i18next.t('Help.A1')} <a href="tel:+37797773000">+377 97 77 30 00</a></p>

                    <p><strong>{i18next.t('Help.Q2')}</strong> {i18next.t('Help.A2')} <a href="tel:+37797773000">+377 97 77 30 00</a></p>

                    <p><strong>{i18next.t('Help.Q3')}</strong> {i18next.t('Help.A3')} <a href="tel:+37799666886">+377 99 66 68 86</a></p>
                </form>
                <div className="row button__row">
                    <div className="col-12 text-center pbmt-logo">
                        <img src={'/img/1280px-Monaco_Telecom_logo.png'} alt="Powered by Monaco Telecom"/>
                    </div>
                </div>
            </div>
        )
    }
}

export default Help;
