import React, { Component } from 'react';
import { Link } from "@reach/router";
import axios from "axios";
import { combinePath, log, error, getApiUrl, matchField } from '../utils/utils';
import config from '../utils/config';
import { Trans } from 'react-i18next';
import FormField from '../common/FormField';
// import ReCaptcha from '../common/ReCaptcha';
import MTCaptcha from '../common/MTCaptcha';
import countryList from 'react-select-country-list';
import { getAreaCodeByIso } from '../utils/iso-to-phone';

class RefillForm extends Component {
    constructor(props) {
        super(props);

        const countryCode = props.countryCode || 'FR';
        this.countries = countryList().getData();
        this.state = {
            lastname: '',
            prename: '',
            email: '',
            emailConfirmation: '',
            phone: getAreaCodeByIso(countryCode),
            berth: '',
            amount: 0,
            currency: 'EUR',
            zip: undefined,
            city: undefined,
            countryCode: countryCode,
            termsAccepted: false,
            coverageAccepted: false,
            isFetching: false,
            packageLabel: this.props.packageLabel || 'test',
            errors: [],
            errorMessage: '',
            locale: props.locale,
            token: '',
            recaptchaAction: 'registration',
            countries: this.countries
        };

        // configure axios
        axios.defaults.headers.common['X-Api-Key'] = config.apiKey;

        this.handleInputChange = this.handleInputChange.bind(this);
        this.setPhoneAreaCode = this.setPhoneAreaCode.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.checkout = this.checkout.bind(this);
        this.hasError = this.hasError.bind(this);
        this.errorMsg = this.errorMsg.bind(this);
        this.verifyCallback = this.verifyCallback.bind(this);
    }

    componentDidUpdate(prevProps) {
        if (prevProps.countryCode !== this.props.countryCode) {
            this.setState({
                countryCode: this.props.countryCode
            });
            this.setPhoneAreaCode(this.props.countryCode);
        }
    }

    handleInputChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        let name = target.name;
        let validChars = true;

        if (target.type === 'select-one') {
            name = 'countryCode';
            this.setPhoneAreaCode(value);
        }

        if (target.name === 'phone') {
            // check phone number for valid characters
            validChars = /^[1-9,+](?:\d{0,25})$/.test(value);
        }

        if (validChars) {
            this.setState({
                [name]: value
            });
        }
    }

    setPhoneAreaCode(iso) {
        // set also phone area code if phone no is empty
        let phone = this.state.phone;
        if (!phone || (phone.length < 5)) {
            phone = getAreaCodeByIso(iso);

            this.setState({
                phone
            });
        }
    }

    handleSubmit(event) {
        this.checkout()
            .then(() => log('Redirect done..'));

        event.preventDefault();
    }

    async checkout() {
        try {
            // update locale here, could have been changed
            let request = {...this.state, locale: this.props.locale, countries: []};
            this.setState({...this.state, isFetching: true});
            if (document.getElementsByName("mtcaptcha-verifiedtoken").length) {
                let token = document.getElementsByName("mtcaptcha-verifiedtoken")[0].value;
                request["token"] = token;
                log('Captcha:', request);
            }

            let serviceUrl  = combinePath(getApiUrl(), 'sepm/checkout', 'locale=' + this.props.locale);
            log('Checkout service URL: ', this.serviceUrl);

            log('The form is submitted: ', request);
            // do not submit state -> latest locale change is not available here
            const response = await axios.post(serviceUrl, request);
            this.setState({...this.state, isFetching: false});
            log('Checkout done, response:', response.data);

            if (response.data.isValid) {
                let redirectUrl = response.data.partialRedirectUrl;

                window.location.href = 'https://payment.' + redirectUrl;
            } else {
                // set error
                this.setState({...this.state, errors: response.data.errors, isFetching: false, errorMessage: response.data.errorMessage});
                setTimeout(() => log('State after validation: ', this.state), 200);
            }
        } catch (e) {
            this.setState({...this.state, isFetching: false});
            error(e);
        }
    };

    hasError(name) {
        const error = this.findField(name);
        return error !== undefined;
    }

    errorMsg(name) {
        const error = this.findField(name);
        return error !== undefined ? error.message : '';
    }

    findField(name) {
        return this.state.errors.find(e => matchField(e, name));
    }

    verifyCallback(token) {
        log('ReCaptcha token:' + token);
        this.setState({...this.state, token: token});
    }

    renderZipCity() {
        const { showZipCity } = config;
        if (!showZipCity) {
            return <div/>
        }

        return <div className="row field__row">
            <FormField
                className="col-md-6 col-sm-12"
                type="text"
                field="zip"
                value={this.state.zip}
                icon="location_on"
                i18nkey="RefillForm.Zip"
                i18ndefault="Code postal*"
                required="required"
                onChange={this.handleInputChange}
                hasError={this.hasError}
                errorMsg={this.errorMsg}
                minLength={config.validation.zip.minLength}
                maxLength={config.validation.zip.maxLength}
            />
            <FormField
                className="col-md-6 col-sm-12"
                type="text"
                field="city"
                value={this.state.city}
                icon="location_on"
                i18nkey="RefillForm.City"
                i18ndefault="Ville*"
                required="required"
                onChange={this.handleInputChange}
                hasError={this.hasError}
                errorMsg={this.errorMsg}
                minLength={config.validation.city.minLength}
                maxLength={config.validation.city.maxLength}
            />
        </div>
    }

    render() {
        return (
            <div className="col-lg-9 col-md-11 col-xs-12">
                <div className="row">
                    <div className="col-12 text-center breadcrumb-container">
                        <div className="breadcrumb-sepm flat">
                            <Link to="/"><span className="hidden-sm-ex">&nbsp;</span><span className="hidden-xs-ex"><Trans>OffersList.Name</Trans></span></Link>
                            <div className="active"><span><Trans>RefillForm.Name</Trans></span></div>
                            <div className="open"><span className="hidden-sm-ex">&nbsp;</span><span className="hidden-xs-ex"><Trans>PaymentForm.Name</Trans></span></div>
                            <div className="open"><span className="hidden-sm-ex">&nbsp;</span><span className="hidden-xs-ex"><Trans>ConfirmForm.Name</Trans></span></div>
                        </div>
                    </div>
                </div>

                {/*<ReCaptcha sitekey={config.recaptchaSiteKey} action={this.state.recaptchaAction} verifyCallback={this.verifyCallback} />*/}
                <form onSubmit={this.handleSubmit} className="registration__form form__text">
                    <div className="row field__row">
                        <div className="col-md-12">
                            <h1 className="text-center refill-header"><Trans i18nKey="RefillForm.Headline">Enregistrement</Trans></h1>
                            <p className="text-center"><Trans>RefillForm.SelectedOffer</Trans> {this.state.packageLabel}</p>
                        </div>
                    </div>
                    <div className="row">
                        <div className={`col-md-12 form-error-message ${this.state.errorMessage ? 'active' : '' }`}>
                            <p>{this.state.errorMessage}</p>
                        </div>

                        <div className={`col-md-12 ${this.hasError('recaptchaAction') ? 'field-error-head' : '' }`}>
                            <small>{this.errorMsg('recaptchaAction')}</small>
                        </div>
                    </div>
                    <div className="row field__row">
                        <div className="col-md-12 text-center">
                            <small className="color--white"><Trans i18nKey="RefillForm.MandatoryHint">Les champs marqués d'un * sont obligatoires et doivent être remplis.</Trans></small>
                        </div>
                    </div>
                    <div className="row field__row">
                        <FormField
                            className="col-md-6 col-sm-12"
                            type="text"
                            field="lastname"
                            value={this.state.lastname}
                            icon="person"
                            i18nkey="RefillForm.LastName"
                            i18ndefault="Nom*"
                            required="required"
                            focus="autoFocus"
                            onChange={this.handleInputChange}
                            hasError={this.hasError}
                            errorMsg={this.errorMsg}
                            minLength={config.validation.lastname.minLength}
                            maxLength={config.validation.lastname.maxLength}
                        />
                        <FormField
                            className="col-md-6 col-sm-12"
                            type="text"
                            field="prename"
                            value={this.state.prename}
                            icon="person"
                            i18nkey="RefillForm.PreName"
                            i18ndefault="Prénom*"
                            required="required"
                            onChange={this.handleInputChange}
                            hasError={this.hasError} errorMsg={this.errorMsg}
                            minLength={config.validation.prename.minLength}
                            maxLength={config.validation.prename.maxLength}
                        />
                    </div>
                    <div className="row field__row">
                        <FormField
                            className="col-md-12"
                            type="text"
                            field="berth"
                            value={this.state.berth}
                            icon="directions_boat"
                            i18nkey="RefillForm.Berth"
                            i18ndefault="Nom du bateau*"
                            required="required"
                            onChange={this.handleInputChange}
                            hasError={this.hasError}
                            errorMsg={this.errorMsg}
                            minLength={config.validation.berth.minLength}
                            maxLength={config.validation.berth.maxLength}
                        />
                    </div>
                    <div className="row field__row">
                        <FormField
                            className="col-md-6 col-sm-12"
                            type="email"
                            field="email"
                            value={this.state.email}
                            icon="mail_outline"
                            i18nkey="RefillForm.Email"
                            i18ndefault="Adresse email*"
                            required="required"
                            onChange={this.handleInputChange}
                            hasError={this.hasError}
                            errorMsg={this.errorMsg}
                            minLength={config.validation.email.minLength}
                            maxLength={config.validation.email.maxLength}
                        />
                        <FormField
                            className="col-md-6 col-sm-12"
                            type="email"
                            field="emailConfirmation"
                            value={this.state.emailConfirmation}
                            icon="mail_outline"
                            i18nkey="RefillForm.EmailConfirmation"
                            i18ndefault="Confirmez votre e-mail*"
                            required="required"
                            onChange={this.handleInputChange}
                            hasError={this.hasError}
                            errorMsg={this.errorMsg}
                            minLength={config.validation.email.minLength}
                            maxLength={config.validation.email.maxLength}
                        />
                    </div>
                    {this.renderZipCity()}
                    <div className="row field__row">
                        <div className="col-md-6 col-sm-12">
                            <div className={`input-icon ${this.hasError('countryCode') ? 'field-error' : '' }`}>
                                <i className="material-icons">language</i>
                                <select value={this.state.countryCode} onChange={this.handleInputChange} className="country-select">
                                    {this.state.countries.map((c, index) => {
                                        return (
                                            <option value={c.value} key={c.value}>{c.label}</option>
                                        )
                                    })}
                                </select>
                                <small>{this.errorMsg('countryCode')}</small>
                            </div>
                        </div>
                        <FormField
                            className="col-md-6 col-sm-12"
                            type="phone"
                            field="phone"
                            value={this.state.phone}
                            icon="call"
                            i18nkey="RefillForm.Phone"
                            i18ndefault="Numéro de téléphone*"
                            pattern={config.validation.phone.pattern}
                            onChange={this.handleInputChange}
                            hasError={this.hasError}
                            errorMsg={this.errorMsg}
                            minLength={config.validation.phone.minLength}
                            maxLength={config.validation.phone.maxLength}
                        />
                    </div>
                    <div className="row">
                        <div className="col-md-6 offset-md-3">
                            <MTCaptcha locale={this.props.locale} />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <div className={`input-checkbox ${this.hasError('termsAccepted') ? 'field-error' : '' }`}>
                                <input type="checkbox" name="termsAccepted" id="termsAccepted" checked={this.state.termsAccepted} onChange={this.handleInputChange} required />
                                <label htmlFor="termsAccepted" />
                            </div>
                            <p className="terms__text">
                                <Trans i18nKey="RefillForm.Terms">
                                    J’ai pris connaissance et j’accepte les <a href={`/documents/${this.props.locale}/CGV Internet.pdf`} target="_blank" rel="noopener noreferrer">Conditions Générales de Vente Internet</a>, la <a href="/documents/fr-FR/Charte Protection des Données Personnelles.pdf" target="_blank" rel="noopener noreferrer">Charte relative à la protection de vos données personnelles</a> et les <a href="/documents/fr-FR/CSV Services Monaco Yachting - offre FIBRE.pdf" target="_blank">Conditions Spécifiques de Vente des Services Monaco Yachting – offre FIBRE</a>.
                                </Trans>*
                            </p>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-12">
                            <div className={`input-checkbox ${this.hasError('coverageAccepted') ? 'field-error' : '' }`}>
                                <input type="checkbox" name="coverageAccepted" id="coverageAccepted" checked={this.state.coverageAccepted} onChange={this.handleInputChange} required />
                                <label htmlFor="coverageAccepted" />
                            </div>
                            <p className="terms__text">
                                <Trans i18nKey="RefillForm.Coverage">
                                    J’ai pris connaissance de la <Link to="/coverage">couverture du service</Link> <br />relative l’emplacement au port du bateau.
                                </Trans>*
                            </p>
                        </div>
                    </div>
                    <div className="row button__row">
                        <div className="col-xs-4">
                            <Link to="/" className="btn btn--primary btn--icon link__button">
                                <span className="btn__text"><i className="icon-Arrow-Back" /> <Trans>RefillForm.Back</Trans></span>
                            </Link>
                        </div>
                        <div className="col">
                            &nbsp;
                        </div>
                        <div className="col-xs-4">
                            <button type="submit" className="btn btn--primary btn--icon btn--icon--right" disabled={this.state.isFetching}>
                                <span className="btn__text"><Trans>RefillForm.Submit</Trans> <i className="icon-Arrow-Forward" /></span>
                            </button>
                        </div>
                    </div>
                </form>
                <div className="row button__row">
                    <div className="col-12 text-center pbmt-logo">
                        <img src={'/img/1280px-Monaco_Telecom_logo.png'} alt="Powered by Monaco Telecom"/>
                    </div>
                </div>
            </div>
        )
    }
}

export default RefillForm;
