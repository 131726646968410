import React, { Component } from 'react';
import { Link } from "@reach/router";
import axios from 'axios';
import { combinePath, log, error, getApiUrl } from '../utils/utils';
import config from '../utils/config';
import { Trans } from 'react-i18next';

class OffersList extends Component {

    constructor(props) {
        super(props);

        let params = new URLSearchParams(window.location.search);

        this.state = {
            isFetching: true,
            isError: false,
            packages: [],
            locale: params.get('locale') || props.locale || config.locale
        };

        this.serviceUrl  = combinePath(getApiUrl(), 'sepm/packages');

        log('Offer list service URL: ', this.serviceUrl);
        // configure axios
        axios.defaults.headers.common['X-Api-Key'] = config.apiKey;

        this.fetchPackages = this.fetchPackages.bind(this);
    }

    componentDidMount() {
        this.fetchPackages()
            .then(() => log('Packages loaded:', this.state));

        if (this.state.locale !== this.props.locale) {
            // state locale comes optionally from parameter and must be used
            this.props.onToggleLocale(this.state.locale);
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.locale !== this.props.locale) {
            this.fetchPackages()
                .then(() => log('Packages refreshed:', this.state));
        }
    }

    async fetchPackages() {
        try {
            // locale can change -> therefore set it here
            let serviceUrl = this.serviceUrl;
            if (this.props.locale) {
                serviceUrl += `?locale=${this.props.locale}`;
            }

            this.setState({...this.state, isFetching: true});
            const response = await axios.get(serviceUrl);
            this.setState({packages: response.data, isFetching: false, isError: false});

            return response.data;
        } catch (e) {
            this.setState({...this.state, isFetching: false, isError: true});
            error(e);
        }
    };

    getSpeed(text) {
        if (!text) return '-';
        let parts = text.split(' (');
        if (parts.length !== 2) return text;
        let speed = parts[1].replace(')', '');
        return speed;
    }

    getDuration(text) {
        if (!text) return '-';
        let parts = text.split(' (');
        if (parts.length !== 2) return text;

        return parts[0];
    }

    getDurationUnit(text) {
        const duration = this.getDuration(text);
        if (!duration) return '-';
        let parts = duration.split(' ');
        if (parts.length !== 2) return duration;

        return parts[1];
    }

    getDurationValue(text) {
        const duration = this.getDuration(text);
        if (!duration) return '-';
        let parts = duration.split(' ');
        if (parts.length !== 2) return duration;

        return parts[0];
    }

    waitingInfo() {
        return <div className="row justify-content-md-center justify-content-lg-center">
            <div className="col-md-6 col-lg-3">
                <form className="form__text"><p><Trans>OffersList.WaitingInfo</Trans></p></form>
            </div>
        </div>;
    }

    errorInfo() {
        return <div className="row justify-content-md-center justify-content-lg-center">
            <div className="col-md-6 col-lg-3">
                <form className="form__text"><p><Trans>OffersList.ErrorInfo</Trans> <Link to={'/contact'} className="inner-link">Contact</Link></p></form>
            </div>
        </div>;
    }

    displayPackages() {
        return <div className="row form__row justify-content-md-center justify-content-lg-center">
            {this.state.packages.map((pkg, index) => {
                let count = this.state.packages.length;
                return (
                    <div className={'col-md-6 ' + (count === 1 ? 'col-lg-4' : 'col-lg-3')} key={index}>
                        <div className="pricing pricing-1 boxed boxed--border boxed--lg text-center">
                            <div className="pricing-header text-center">
                                <span>{this.getDurationValue(pkg.text)}<br />{this.getDurationUnit(pkg.text)}</span>
                            </div>
                            <span className="h2"><strong>{pkg.price}<span className="pricing__dollar">{pkg.currency}</span></strong></span>
                            <p className="text-center speed">
                                <img src={pkg.image} alt="Speed icon" width="60" />
                                <span>{this.getSpeed(pkg.text)}</span>
                            </p>
                            <p className="offer__description">
                                <small>{pkg.description}</small>
                            </p>
                            <p>
                                <Link to={'/refill/' + pkg.package} className="btn btn--sm btn-pricing">
                                    <span className="btn__text"><Trans>OffersList.SelectOffer</Trans></span>
                                </Link>
                            </p>
                        </div>
                    </div>
                )
            })}
        </div>;
    }

    render() {
        const isFetching = this.state.isFetching;
        const isError = this.state.isError;
        let content;
        if (isFetching) {
            content = this.waitingInfo();
        } else {
            if (isError) {
                content = this.errorInfo();
            } else {
                content = this.displayPackages();
            }
        }

        return (
            <div>
                <div className="col-12 text-center">
                    <div className="breadcrumb-sepm flat">
                        <div className="active"><span><Trans>OffersList.Name</Trans></span></div>
                        <div className="open"><span className="hidden-sm-ex">&nbsp;</span><span className="hidden-xs-ex"><Trans>RefillForm.Name</Trans></span></div>
                        <div className="open"><span className="hidden-sm-ex">&nbsp;</span><span className="hidden-xs-ex"><Trans>PaymentForm.Name</Trans></span></div>
                        <div className="open"><span className="hidden-sm-ex">&nbsp;</span><span className="hidden-xs-ex"><Trans>ConfirmForm.Name</Trans></span></div>
                    </div>
                </div>
                <div className="col-10 offset-1 text-center">
                    <h1>Monaco Yachting Internet</h1>
                    <h3 className="sub__headline"><Trans>OffersList.SubHeadline</Trans></h3>
                </div>
                <div className="col-12 text-center">
                    {content}
                </div>
                <div className="col-12 text-center pbmt-logo">
                    <img src={'/img/1280px-Monaco_Telecom_logo.png'} alt="Powered by Monaco Telecom"/>
                </div>
            </div>
        )
    }
}

export default OffersList;
