import React, { Component } from 'react';
import './App.css';
import NavContainer from "./containers/NavContainer";
import {Router} from "@reach/router";
import RefillContainer from "./containers/RefillContainer";
import ConfirmContainer from "./containers/ConfirmContainer";
import OffersContainer from "./containers/OffersContainer";
import HelpContainer from './containers/HelpContainer';
import ContactContainer from './containers/ContactContainer';
import CoverageContainer from './containers/CoverageContainer';
import { getLangFromLocale, log } from './utils/utils';
import config from './utils/config';
import { withTranslation } from 'react-i18next';
// import loadReCaptcha from './utils/loadReCaptcha';

class App extends Component  {
    constructor(props) {
        super(props);
        const { locale } = config;
        this.state = {locale};

        // this binding is necessary to make `this` work in the callback
        this.toggleLocale = this.toggleLocale.bind(this);
    }

    componentDidMount() {
        // init Google ReCaptcha
        // loadReCaptcha(config.recaptchaSiteKey);
    }

    toggleLocale(locale) {
        let countryCode = '';
        switch (locale) {
            case 'fr-FR': countryCode = 'FR'; break;
            case 'en-US': countryCode = 'GB'; break;
            case 'it-IT': countryCode = 'IT'; break;
            default: countryCode = 'FR';
        }

        this.setState({...this.state, locale, countryCode});
        this.props.i18n.changeLanguage(locale);

        // eslint-disable-next-line no-undef
        if (window.mtcaptcha && mtcaptchaConfig) {
            const lang = getLangFromLocale(locale);
            // eslint-disable-next-line no-undef
            mtcaptchaConfig.lang = lang;
            setTimeout(() => {
                window.mtcaptcha.resetUI();
            }, 200);
            // eslint-disable-next-line no-undef
            log('Captcha language was changed:', lang, mtcaptchaConfig);
        }

        log('Root locale was changed:', locale, countryCode);
    }

    render() {
        return (
            <div className="App">
                <div id="start"/>
                <NavContainer locale={this.state.locale} onToggleLocale={this.toggleLocale}/>
                <Router>
                    <RefillContainer path="/refill/:packageLabel" locale={this.state.locale} countryCode={this.state.countryCode} />
                    <ConfirmContainer path="/confirm" locale={this.state.locale} onToggleLocale={this.toggleLocale} />
                    <HelpContainer path="/help" locale={this.state.locale} onToggleLocale={this.toggleLocale} />
                    <ContactContainer path="/contact" locale={this.state.locale} />
                    <CoverageContainer path="/coverage" locale={this.state.locale} />
                    <OffersContainer path="/" locale={this.state.locale} onToggleLocale={this.toggleLocale} />
                </Router>
                {/*<Link className="back-to-top inner-link" to="#start" data-scroll-class="100vh:active">
                    <img src={'/img/1280px-Monaco_Telecom_logo.png'} alt=""/>
                </Link>*/}
                <small className="version-no">V.{config.version}</small>
            </div>
        )
    }
}

export default withTranslation()(App);
