let country2phone = undefined;

export const getAreaCodeByIso = (iso) => {
    if (!country2phone) {
        init();
    }

    return country2phone.get(iso.toUpperCase());
};

const init = () => {
    country2phone = new Map();

    country2phone.set('AF', '+93');
    country2phone.set('AL', '+355');
    country2phone.set('DZ', '+213');
    country2phone.set('AD', '+376');
    country2phone.set('AO', '+244');
    country2phone.set('AG', '+1-268');
    country2phone.set('AR', '+54');
    country2phone.set('AM', '+374');
    country2phone.set('AU', '+61');
    country2phone.set('AT', '+43');
    country2phone.set('AZ', '+994');
    country2phone.set('BS', '+1-242');
    country2phone.set('BH', '+973');
    country2phone.set('BD', '+880');
    country2phone.set('BB', '+1-246');
    country2phone.set('BY', '+375');
    country2phone.set('BE', '+32');
    country2phone.set('BZ', '+501');
    country2phone.set('BJ', '+229');
    country2phone.set('BT', '+975');
    country2phone.set('BO', '+591');
    country2phone.set('BA', '+387');
    country2phone.set('BW', '+267');
    country2phone.set('BR', '+55');
    country2phone.set('BN', '+673');
    country2phone.set('BG', '+359');
    country2phone.set('BF', '+226');
    country2phone.set('BI', '+257');
    country2phone.set('KH', '+855');
    country2phone.set('CM', '+237');
    country2phone.set('CA', '+1');
    country2phone.set('CV', '+238');
    country2phone.set('CF', '+236');
    country2phone.set('TD', '+235');
    country2phone.set('CL', '+56');
    country2phone.set('CN', '+86');
    country2phone.set('CO', '+57');
    country2phone.set('KM', '+269');
    country2phone.set('CD', '+243');
    country2phone.set('CG', '+242');
    country2phone.set('CR', '+506');
    country2phone.set('CI', '+225');
    country2phone.set('HR', '+385');
    country2phone.set('CU', '+53');
    country2phone.set('CY', '+357');
    country2phone.set('CZ', '+420');
    country2phone.set('DK', '+45');
    country2phone.set('DJ', '+253');
    country2phone.set('DM', '+1-767');
    country2phone.set('DO', '+1-809and1-829');
    country2phone.set('EC', '+593');
    country2phone.set('EG', '+20');
    country2phone.set('SV', '+503');
    country2phone.set('GQ', '+240');
    country2phone.set('ER', '+291');
    country2phone.set('EE', '+372');
    country2phone.set('ET', '+251');
    country2phone.set('FJ', '+679');
    country2phone.set('FI', '+358');
    country2phone.set('FR', '+33');
    country2phone.set('GA', '+241');
    country2phone.set('GM', '+220');
    country2phone.set('GE', '+995');
    country2phone.set('DE', '+49');
    country2phone.set('GH', '+233');
    country2phone.set('GR', '+30');
    country2phone.set('GD', '+1-473');
    country2phone.set('GT', '+502');
    country2phone.set('GN', '+224');
    country2phone.set('GW', '+245');
    country2phone.set('GY', '+592');
    country2phone.set('HT', '+509');
    country2phone.set('HN', '+504');
    country2phone.set('HU', '+36');
    country2phone.set('IS', '+354');
    country2phone.set('IN', '+91');
    country2phone.set('ID', '+62');
    country2phone.set('IR', '+98');
    country2phone.set('IQ', '+964');
    country2phone.set('IE', '+353');
    country2phone.set('IL', '+972');
    country2phone.set('IT', '+39');
    country2phone.set('JM', '+1-876');
    country2phone.set('JP', '+81');
    country2phone.set('JO', '+962');
    country2phone.set('KZ', '+7');
    country2phone.set('KE', '+254');
    country2phone.set('KI', '+686');
    country2phone.set('KP', '+850');
    country2phone.set('KR', '+82');
    country2phone.set('KW', '+965');
    country2phone.set('KG', '+996');
    country2phone.set('LA', '+856');
    country2phone.set('LV', '+371');
    country2phone.set('LB', '+961');
    country2phone.set('LS', '+266');
    country2phone.set('LR', '+231');
    country2phone.set('LY', '+218');
    country2phone.set('LI', '+423');
    country2phone.set('LT', '+370');
    country2phone.set('LU', '+352');
    country2phone.set('MK', '+389');
    country2phone.set('MG', '+261');
    country2phone.set('MW', '+265');
    country2phone.set('MY', '+60');
    country2phone.set('MV', '+960');
    country2phone.set('ML', '+223');
    country2phone.set('MT', '+356');
    country2phone.set('MH', '+692');
    country2phone.set('MR', '+222');
    country2phone.set('MU', '+230');
    country2phone.set('MX', '+52');
    country2phone.set('FM', '+691');
    country2phone.set('MD', '+373');
    country2phone.set('MC', '+377');
    country2phone.set('MN', '+976');
    country2phone.set('ME', '+382');
    country2phone.set('MA', '+212');
    country2phone.set('MZ', '+258');
    country2phone.set('MM', '+95');
    country2phone.set('NA', '+264');
    country2phone.set('NR', '+674');
    country2phone.set('NP', '+977');
    country2phone.set('NL', '+31');
    country2phone.set('NZ', '+64');
    country2phone.set('NI', '+505');
    country2phone.set('NE', '+227');
    country2phone.set('NG', '+234');
    country2phone.set('NO', '+47');
    country2phone.set('OM', '+968');
    country2phone.set('PK', '+92');
    country2phone.set('PW', '+680');
    country2phone.set('PA', '+507');
    country2phone.set('PG', '+675');
    country2phone.set('PY', '+595');
    country2phone.set('PE', '+51');
    country2phone.set('PH', '+63');
    country2phone.set('PL', '+48');
    country2phone.set('PT', '+351');
    country2phone.set('QA', '+974');
    country2phone.set('RO', '+40');
    country2phone.set('RU', '+7');
    country2phone.set('RW', '+250');
    country2phone.set('KN', '+1-869');
    country2phone.set('LC', '+1-758');
    country2phone.set('VC', '+1-784');
    country2phone.set('WS', '+685');
    country2phone.set('SM', '+378');
    country2phone.set('ST', '+239');
    country2phone.set('SA', '+966');
    country2phone.set('SN', '+221');
    country2phone.set('RS', '+381');
    country2phone.set('SC', '+248');
    country2phone.set('SL', '+232');
    country2phone.set('SG', '+65');
    country2phone.set('SK', '+421');
    country2phone.set('SI', '+386');
    country2phone.set('SB', '+677');
    country2phone.set('SO', '+252');
    country2phone.set('ZA', '+27');
    country2phone.set('ES', '+34');
    country2phone.set('LK', '+94');
    country2phone.set('SD', '+249');
    country2phone.set('SR', '+597');
    country2phone.set('SZ', '+268');
    country2phone.set('SE', '+46');
    country2phone.set('CH', '+41');
    country2phone.set('SY', '+963');
    country2phone.set('TJ', '+992');
    country2phone.set('TZ', '+255');
    country2phone.set('TH', '+66');
    country2phone.set('TL', '+670');
    country2phone.set('TG', '+228');
    country2phone.set('TO', '+676');
    country2phone.set('TT', '+1-868');
    country2phone.set('TN', '+216');
    country2phone.set('TR', '+90');
    country2phone.set('TM', '+993');
    country2phone.set('TV', '+688');
    country2phone.set('UG', '+256');
    country2phone.set('UA', '+380');
    country2phone.set('AE', '+971');
    country2phone.set('GB', '+44');
    country2phone.set('US', '+1');
    country2phone.set('UY', '+598');
    country2phone.set('UZ', '+998');
    country2phone.set('VU', '+678');
    country2phone.set('VA', '+379');
    country2phone.set('VE', '+58');
    country2phone.set('VN', '+84');
    country2phone.set('YE', '+967');
    country2phone.set('ZM', '+260');
    country2phone.set('ZW', '+263');
    country2phone.set('GE', '+995');
    country2phone.set('TW', '+886');
    country2phone.set('AZ', '+374-97');
    country2phone.set('CY', '+90-392');
    country2phone.set('MD', '+373-533');
    country2phone.set('SO', '+252');
    country2phone.set('GE', '+995');
    country2phone.set('CX', '+61');
    country2phone.set('CC', '+61');
    country2phone.set('NF', '+672');
    country2phone.set('NC', '+687');
    country2phone.set('PF', '+689');
    country2phone.set('YT', '+262');
    country2phone.set('GP', '+590');
    country2phone.set('GP', '+590');
    country2phone.set('PM', '+508');
    country2phone.set('WF', '+681');
    country2phone.set('CK', '+682');
    country2phone.set('NU', '+683');
    country2phone.set('TK', '+690');
    country2phone.set('GG', '+44');
    country2phone.set('IM', '+44');
    country2phone.set('JE', '+44');
    country2phone.set('AI', '+1-264');
    country2phone.set('BM', '+1-441');
    country2phone.set('IO', '+246');
    country2phone.set('', '+357');
    country2phone.set('VG', '+1-284');
    country2phone.set('KY', '+1-345');
    country2phone.set('FK', '+500');
    country2phone.set('GI', '+350');
    country2phone.set('MS', '+1-664');
    country2phone.set('SH', '+290');
    country2phone.set('TC', '+1-649');
    country2phone.set('MP', '+1-670');
    country2phone.set('PR', '+1-787and1-939');
    country2phone.set('AS', '+1-684');
    country2phone.set('GU', '+1-671');
    country2phone.set('VI', '+1-340');
    country2phone.set('HK', '+852');
    country2phone.set('MO', '+853');
    country2phone.set('FO', '+298');
    country2phone.set('GL', '+299');
    country2phone.set('GF', '+594');
    country2phone.set('GP', '+590');
    country2phone.set('MQ', '+596');
    country2phone.set('RE', '+262');
    country2phone.set('AX', '+358-18');
    country2phone.set('AW', '+297');
    country2phone.set('AN', '+599');
    country2phone.set('SJ', '+47');
    country2phone.set('AC', '+247');
    country2phone.set('TA', '+290');
    country2phone.set('CS', '+381');
    country2phone.set('PS', '+970');
    country2phone.set('EH', '+212');
};
