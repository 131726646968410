import React, { Component } from 'react';
import { Link } from '@reach/router';
import i18next from 'i18next';

class VerticalMenu extends Component {
    render() {
        return (
            <ul className="menu-vertical">
                <li>
                    <Link to="/" className="inner-link" onClick={this.props.toggleMenu}>{i18next.t('VerticalMenu.Home', 'Home')}</Link>
                </li>
                <li>
                    <Link to={'/help'} className="inner-link" onClick={this.props.toggleMenu}>{i18next.t('VerticalMenu.Help', 'Aide')}</Link>
                </li>
                <li>
                    <Link to={'/contact'} className="inner-link" onClick={this.props.toggleMenu}>{i18next.t('VerticalMenu.Contact', 'Contact')}</Link>
                </li>
                <li>
                    <Link to={'/coverage'} className="inner-link" onClick={this.props.toggleMenu}>{i18next.t('VerticalMenu.Coverage', 'Couverture')}</Link>
                </li>
                <li>
                    <a className="inner-link" href="https://www.pagesjaunesmonaco.com" target="_blank" rel="noopener noreferrer">{i18next.t('VerticalMenu.PhoneBook', 'Annuaire')}</a>
                </li>
                <li>
                    <a className="inner-link" href="http://www.meteoconsult.fr/meteo-france/prevision-meteo-france.php" target="_blank" rel="noopener noreferrer">{i18next.t('VerticalMenu.Weather', 'Meteo')}</a>
                </li>
                <li>
                    <a className="inner-link" href="http://www.visitmonaco.com" target="_blank" rel="noopener noreferrer">{i18next.t('VerticalMenu.ToVisit', 'Visiter')}</a>
                </li>
                <li>
                    <a className="inner-link" href="http://www.ports-monaco.com" target="_blank" rel="noopener noreferrer">{i18next.t('VerticalMenu.SEPM', 'SEPM')}</a>
                </li>
                <li>
                    <a className="inner-link" href="https://www.monaco-telecom.mc" target="_blank" rel="noopener noreferrer">{i18next.t('VerticalMenu.MT', 'Monaco telecom')}</a>
                </li>
                <li>
                    <a className="inner-link" href="http://www.ffports-plaisance.com" target="_blank" rel="noopener noreferrer">{i18next.t('VerticalMenu.Marinas', 'FFPP')}</a>
                </li>
            </ul>
        )
    }
}
export default VerticalMenu;
