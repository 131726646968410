import React from 'react';
import MainContainer from "./MainContainer";
import OffersList from "../pages/OffersList";

export default function OffersContainer(props) {
    return (
        <MainContainer className="container">
            <OffersList locale={props.locale}  onToggleLocale={props.onToggleLocale} />
        </MainContainer>
    );
}
