import React from 'react';
import MainContainer from "./MainContainer";
import Help from "../pages/Help";

export default function HelpContainer(props) {
    return (
        <MainContainer className="container pos-vertical-center">
            <Help locale={props.locale} onToggleLocale={props.onToggleLocale} />
        </MainContainer>
    );
}
