import React from 'react';
import MainContainer from "./MainContainer";
import Coverage from "../pages/Coverage";

export default function CoverageContainer(props) {
    return (
        <MainContainer className="container pos-vertical-center">
            <Coverage locale={props.locale} />
        </MainContainer>
    );
}
