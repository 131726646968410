import React, { useEffect } from 'react'
import { getLangFromLocale } from '../utils/utils';
import env from '@beam-australia/react-env';

const MTCaptcha = (props) => {
    useEffect(() => {
        const lang = getLangFromLocale(props.locale);
        if (!document.getElementById('mtscript')) {
            const s = document.createElement('script');
            s.type = 'text/javascript';
            s.async = true;
            s.id = 'mtscript';
            // s.innerHTML = 'var mtcaptchaConfig = {"sitekey": "' + process.env.REACT_APP_MT_PUBLIC_KEY + '", "action": "registration", "lang": "' + lang + '", "customStyle": { "cardColor": "#336699", "placeHolderColor": "#FFFFFF", "inputTextColor": "#FFFFFF", "inputBorderColor": { "byDefault": "#FFFFFF", "active": "#FFFFFF" }, "buttonIconColor": { "refresh": "#FFFFFF", "audio": "#FFFFFF" } } };';
            s.innerHTML = 'var mtcaptchaConfig = {"sitekey": "' + env("MT_PUBLIC_KEY") + '", "action": "registration", "lang": "' + lang + '", "widgetSize": "mini", "customStyle": { "cardColor": "#114477", "cardBorder": "1px solid #FFFFFF", "inputTextColor": "#FFFFFF", "placeHolderColor": "#FFFFFF", "msgTextColor": "#CCCCCC", "inputBackgroundColor": "#336699", "inputBorderColor": { "byDefault": "#FFFFFF", "active": "#FFFFFF" }, "buttonIconColor": { "refresh": "#FFFFFF", "audio": "#FFFFFF" } } };';
            document.body.appendChild(s);
        } else {
            // eslint-disable-next-line no-undef
            mtcaptchaConfig.lang = lang;
        }

        if (!document.getElementById('mtservice')) {
            const mt_service = document.createElement("script");
            mt_service.src = "https://service.mtcaptcha.com/mtcv1/client/mtcaptcha.min.js";
            mt_service.async = true;
            mt_service.id = 'mtservice';
            document.body.appendChild(mt_service);
        }

        if (!document.getElementById('mtservice2')) {
            const mt_service2 = document.createElement("script");
            mt_service2.src = "https://service2.mtcaptcha.com/mtcv1/client/mtcaptcha2.min.js";
            mt_service2.async = true;
            mt_service2.id = 'mtservice2';
            document.body.appendChild(mt_service2);
        } else {
            setTimeout(() => {
                window.mtcaptcha.renderUI();
            }, 500);
        }
    }, [])
    return (<div className='mtcaptcha' />)
}

export default MTCaptcha;
