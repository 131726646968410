import React, { Component } from 'react';
import LanguageMenu from "../common/LanguageMenu";
import VerticalMenu from "../common/VerticalMenu";
import { Link } from "@reach/router";

class NavContainer extends Component {
    constructor(props) {
        super(props);

        this.state = {isMenuOn: false};

        // this binding is necessary to make `this` work in the callback
        this.toggleMenu = this.toggleMenu.bind(this);
    }

    toggleMenu(e) {
        this.setState(state => ({
            isMenuOn: !state.isMenuOn
        }));
    }

    render() {
        return (
            <div className="nav-container">
                <nav className="bar bar-toggle bar--transparent bar--absolute bg--dark" data-fixed-at="200">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col col-toggle">
                                <div className="bar__module menutoggle">
                                    <a href="#" className="menu-toggle pull-right" data-notification-link="sidebar-menu" onClick={this.toggleMenu}>
                                        <i className="stack-interface stack-menu" />
                                    </a>
                                </div>
                            </div>
                            <div className="col col-logo">
                                <div className="bar__module">
                                    <Link to="/">
                                        <img className="logo logo-dark" alt="dark version" src={'/img/hr_logo_sepm_02.png'} />
                                        <img className="logo logo-light" alt="light version" src={'/img/hr_logo_sepm_02.png'} />
                                    </Link>
                                </div>
                            </div>
                            <div className="col d-flex justify-content-end">
                                <LanguageMenu locale={this.props.locale} onToggleLocale={this.props.onToggleLocale}/>
                            </div>
                        </div>
                    </div>
                </nav>
                <div className={'notification pos-left pos-top side-menu bg--white notification--reveal ' + (this.state.isMenuOn ? null : 'notification--dismissed')} data-notification-link="sidebar-menu" data-animation="from-left">
                    <div className="notification-close-cross notification-close" onClick={this.toggleMenu} />
                    <div className="side-menu__module pos-vertical-center text-center">
                        <VerticalMenu toggleMenu={this.toggleMenu} />
                    </div>
                </div>
            </div>
        )
    }
}
export default NavContainer; // Don’t forget to use export default!
