import React, { Component } from 'react';

class LanguageMenu extends Component {
    constructor(props) {
        super(props);

        // this binding is necessary to make `this` work in the callback
        this.toggleLocale = this.toggleLocale.bind(this);
    }

    toggleLocale(e, locale) {
        e.preventDefault();
        this.props.onToggleLocale(locale);
    }

    render() {
        return (
            <div className="bar__module">
                <ul className="social-list list-inline text-center list--hover">
                    <li>
                        <a className={this.props.locale === 'fr-FR' ? 'lang-active' : ''} href="fr-FR" onClick={(e) => this.toggleLocale(e, 'fr-FR')}>
                            FR
                        </a>
                    </li>
                    <li>
                        <a className={this.props.locale === 'en-US' ? 'lang-active' : ''} href="en-US" onClick={(e) => this.toggleLocale(e, 'en-US')}>
                            ENG
                        </a>
                    </li>
                    <li>
                        <a className={this.props.locale === 'it-IT' ? 'lang-active' : ''} href="it-IT" onClick={(e) => this.toggleLocale(e, 'it-IT')}>
                            IT
                        </a>
                    </li>
                </ul>
            </div>
        )
    }
}

export default LanguageMenu; // Don’t forget to use export default!
