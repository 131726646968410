import React, { Component } from 'react';
import axios from "axios";
import { combinePath, log, error, getApiUrl } from '../utils/utils';
import config from '../utils/config';
import { Link } from '@reach/router';
import { Trans } from 'react-i18next';
import i18next from 'i18next';

class ConfirmForm extends Component {
    constructor(props) {
        super(props);

        let params = new URLSearchParams(window.location.search);

        log('Confirm props / params:', this.props, params);

        this.serviceUrl  = combinePath(getApiUrl(), 'sepm/confirm');
        log('Service URL: ', this.serviceUrl);
        // configure axios
        axios.defaults.headers.common['X-Api-Key'] = config.apiKey;

        this.state = {
            returnMac: params.get('RETURNMAC'),
            hostedCheckoutId: params.get('hostedCheckoutId'),
            locale: params.get('locale'),
            email: params.get('email'),
            packageID: params.get('package') || 'test',
            isFetching: true,
            isError: false,
            isWarning: false,
            warning: '',
            userName: params.get('userName') || '',
            password: params.get('password') || '',
            description: '',
            validity: '',
            status: 0,
            userRef: ''
        };

        this.confirm = this.confirm.bind(this);
    }

    componentDidMount() {
        this.confirm()
            .then(() => log('Confirm done:', this.state));

        if (this.state.locale !== this.props.locale) {
            // state locale comes from Ingenico ReturnUrl and must be used
            this.props.onToggleLocale(this.state.locale);
        }
    }

    async confirm() {
        try {
            let serviceUrl  = combinePath(getApiUrl(), 'sepm/confirm', 'locale=' + this.state.locale);
            log('Confirm service URL: ', this.serviceUrl);

            const response = await axios.post(serviceUrl, this.state);
            this.setState({
                ...this.state,
                isFetching: false,
                isError: !response.data.isValid,
                isWarning: response.data.isWarning,
                warning: response.data.warning,
                email: response.data.email,
                userName: response.data.userName,
                password: response.data.password,
                description: response.data.offerDescription,
                validity: response.data.offerValidity,
                status: response.data.status,
                userRef: response.data.userRef
            });
        } catch (e) {
            this.setState({...this.state, isFetching: false, isError: true, status: -99});
            error(e);
        }
    };

    displayResult() {
        return <div>
            <div className="row field__row">
                <div className={`col-md-12 form-error-message ${this.state.isWarning ? 'active' : '' }`}>
                    <p>{this.state.warning}</p>
                </div>
            </div>
            <div className="row field__row">
                <div className="col-md-12">
                    <h3 className="text-center"><Trans i18nKey="ConfirmForm.SubHeadline">Votre souscription est confirmée.</Trans></h3>
                    <p>
                        {i18next.t('ConfirmForm.ConfirmMessage', { description: this.state.description, validity: this.state.validity })}
                    </p>
                </div>
            </div>
            <div className="row field__row">
                <div className="col-md-12">
                    <div className="input-icon">
                        <i className="material-icons">person</i>
                        <input type="text" name="userName" placeholder={i18next.t('ConfirmForm.UserName', 'Identifiant')} value={this.state.userName} readOnly/>
                    </div>
                </div>
            </div>
            <div className="row field__row">
                <div className="col-md-12">
                    <div className="input-icon">
                        <i className="material-icons">lock</i>
                        <textarea name="password" placeholder={i18next.t('ConfirmForm.UserName', 'Mot de passe')} value={this.state.password} readOnly rows={this.state.password.length > 20 ? 2 : 1} />
                    </div>
                </div>
            </div>
            <div className="row field__row">
                <div className="col-md-12">
                    <div className="input-icon">
                        <i className="material-icons">mail_outline</i>
                        <input type="text" name="email" placeholder={i18next.t('ConfirmForm.Email', 'Adresse email')} value={this.state.email} readOnly />
                    </div>
                </div>
            </div>
            <div className="row field__row button__row">
                <div className="col-md-12 text-center">
                    <span className="color--white"><Trans i18nKey="ConfirmForm.EmailHint">Vos identifiants et votre facture vous sont envoyés par mail.</Trans></span>
                </div>
                <div className="col-sm-12 text-center">
                    <span className="color--white"><Trans i18nKey="ConfirmForm.ConnectionHint">Connectez vous directement à votre borne portuaire à votre arrivée.</Trans></span>
                    {/*<button type="submit" className="btn btn--primary"><span className="btn__text">S'ENREGISTRER</span></button>*/}
                </div>
            </div>
            <div className="row field__row button__row">
                <div className="col-sm-12 text-center">
                    <a href="http://www.ports-monaco.com" className="btn btn--primary">
                        <span className="btn__text">{i18next.t('ConfirmForm.Finish', 'TERMINER')}</span>
                    </a>
                </div>
            </div>
        </div>;
    }

    waitingInfo() {
        return <div className="row form__row justify-content-md-center justify-content-lg-center">
            <div className="col-md-12 col-lg-10">
                <p><Trans i18nKey="ConfirmForm.WaitingInfo">Le résultat du processus de paiement est en cours de vérification, veuillez patienter..</Trans></p>
            </div>
        </div>;
    }

    errorInfo() {
        return <div className="row form__row justify-content-md-center justify-content-lg-center">
            <div className="col-md-12 col-lg-10">
                <p><Trans i18nKey="ConfirmForm.ErrorInfo">Une erreur (status={this.state.status}, user ref={this.state.userRef}) de vérification du paiement interne s'est produite:</Trans> <Link to={'/contact'} className="inner-link">Contact</Link></p>
            </div>
        </div>;
    }


    paymentErrorInfo() {
        return <div className="row form__row justify-content-md-center justify-content-lg-center">
            <div className="col-md-12 col-lg-10">
                <p><Trans i18nKey="ConfirmForm.PaymentErrorInfo">Le paiement a échoué, veuillez redémarrer:</Trans> <Link to={'/'} className="inner-link">Home</Link></p>
            </div>
        </div>;
    }

    render() {
        const isFetching = this.state.isFetching;
        const isError = this.state.isError;
        const status = this.state.status;
        let content;
        if (isFetching) {
            content = this.waitingInfo();
        } else {
            if (isError) {
                if (status === config.paymentErrorCode) {
                    content = this.paymentErrorInfo();
                } else {
                    content = this.errorInfo();
                }
            } else {
                content = this.displayResult();
            }
        }

        return (
            <div className="col-lg-7 col-md-9">
                <div className="row">
                    <div className="col-12 text-center">
                        <div className="breadcrumb-sepm flat">
                            <Link to="/"><span className="hidden-sm-ex">&nbsp;</span><span className="hidden-xs-ex"><Trans>OffersList.Name</Trans></span></Link>
                            <div><span className="hidden-sm-ex">&nbsp;</span><span className="hidden-xs-ex"><Trans>RefillForm.Name</Trans></span></div>
                            <div><span className="hidden-sm-ex">&nbsp;</span><span className="hidden-xs-ex"><Trans>PaymentForm.Name</Trans></span></div>
                            <div className="active"><span><Trans>ConfirmForm.Name</Trans></span></div>
                        </div>
                    </div>
                </div>
                <h1 className="text-center"><Trans i18nKey="ConfirmForm.Headline">Merci !</Trans></h1>
                <form className="form__text">
                    {content}
                </form>
                <div className="row button__row">
                    <div className="col-12 text-center pbmt-logo">
                        <img src={'/img/1280px-Monaco_Telecom_logo.png'} alt="Powered by Monaco Telecom"/>
                    </div>
                </div>
            </div>
        )
    }
}

export default ConfirmForm;
