import env from '@beam-australia/react-env';

const config = {
    apiUrl: env('API_URL'), //'http://selfcare.yachting-club.mc/api/v1/', // 'https://mt-sepm-api.evanto.de/api/v1/', //
    localUrl: env('LOCAL_URL'), //'http://localhost:8082/api/v1', // https://localhost:44325/api/v1',
    debugMode: env('DEBUG_MODE'), //true,
    locale: env('LOCALE'), //'fr-FR',
    showZipCity: false,
    apiKey: env('API_KEY'), //'D3D1DBB1-0A71-4B71-987D-4E7A276BC06A',
    version: "0.9.4.3",
    paymentErrorCode: -3,
    validation: {
        lastname: {
            maxLength: 50,
            minLength: 2
        },
        prename: {
            maxLength: 50,
            minLength: 2
        },
        berth: {
            maxLength: 30,
            minLength: 2
        },
        email: {
            maxLength: 50,
            minLength: 6
        },
        zip: {
            maxLength: 10,
            minLength: 4
        },
        city: {
            maxLength: 50,
            minLength: 2
        },
        phone: {
            maxLength: 20,
            minLength: 2,
            pattern: null // "[0-9]{3}-[0-9]{3}-[0-9]{4}"
        }
    }
};

export default config;
